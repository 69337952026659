import React, { useEffect } from 'react';
import KeyboardTest from '../components/KeyboardTest/KeyboardTest'
import WebCamTest from '../components/WebCamTest/WebCamTest';
import MicTest from '../components/MicTest/MicTest';
import useWindowDimensions from '../hooks/useWindowDimensions';
import ReactGA from 'react-ga';

import './style/Home.css'

// import AdSense from 'react-adsense';


function Home() {
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        ReactGA.initialize('UA-88585004-2');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    return (
        <div className="homeContentContainer">
            {width > 768 ? <>
                <div className="leftAdContainer">
                    <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=11&l=ez&f=ifr&linkID=18b56ec52a58c72a181136279a7edbc3&t=computests-20&tracking_id=computests-20" width="120" height="600" scrolling="no" border="0" marginwidth="0" style={{ border: 'none' }} frameborder="0"></iframe>
                
                </div>
                <div className="homeCenterContentContainer">
                    <div className="topAdContainer">
                        <div id="container-e02cad415a663b20862cf8a9538b2357"></div>

                        {/* <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ez&f=ifr&linkID=532e26d34834efe7cef9ac1697c0356a&t=computests-20&tracking_id=computests-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style={{ border: 'none' }} frameborder="0"></iframe> */}
                    </div>
                    <div className="TestComponentContainer">
                        <div>
                            <div>
                                <MicTest />
                            </div>
                            <div>
                                <WebCamTest />
                            </div>
                        </div>
                        <div>
                            <KeyboardTest />
                        </div>
                    </div>
                    <div className="websiteAboutContentContainer">
                        <p>In August 2015, former marketing professionals at Vector Marketing Corporation, decided totake the leap from "9 to 5" to "self-employed." After recognizing the need for a professionalmobile device repair business, They opened their first location on Virginia Tech campus inBlacksburg Virginia, and were truly a "mobile" company - Orada Tech and their techniciansbegan to service their customers on-site, no matter the location. No local Starbucks,classrooms, or houses, were too far for the Orada Tech team, as they traveled to each andevery customer in the area. "We didn’t have a website or a physical location. Everycustomer found us solely through social media and word of mouth," says Co-FounderJusten Tornabene.

                        Carrying their toolkits from customer to customer, the Orada Tech team utilized every method oftravel at their disposal- they traveled by foot, car, train, and bus to ensure that every customer received a repair as quickly as possible. Fast and efficient service continues to be Orada Tech’smission statement to this day. cans.

                        From one happy customer to the next, John and Justen's company began to grow. "We had no idea how quickly our company would grow," says Co-Founder John Dodaro, "and we began looking atmore opportunities to expand our services so that we could provide repair solutions to as manypeople as we could."

                        Less than a year later, Orada Tech soon found itself opening its first office on Wall Street in New YorkCity. At ages 18 and 19, Justen Tornabene and John Dodaro became the youngest business ownerson Wall Street at the time. "Opening an office on Wall Street was a dream come true," recalls Justen."It was a lot of work and we worked a brutal pace commuting from our hometowns of NJ everyday."In order to generate their new location’s initial business, John and Justen would stand in subwaystations and street corners handing out flyers advertising their business. Their direct marketing approach and their social media skills soon got the two entrepeneurs some of their first corporateclients. "We realized the need that many businesses had in repairing and maintaining their officedevices," said John Dodaro.

                            Less than a year after the opening of their New York office, John and Justen opened yet anotherlocation, right in their hometown of Hazlet, New Jersey. Since August 2016, their main office locatedat 82 Bethany Road has been repairing customers’ tablets, cell phones, and computers, andcontinues to service the local community. Their plans for the future include opening up morelocations, and assisting their local communities even more.</p>
                    </div>
                </div>

                <div className="rightAdContainer">
                    <iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=11&l=ez&f=ifr&linkID=18b56ec52a58c72a181136279a7edbc3&t=computests-20&tracking_id=computests-20" width="120" height="600" scrolling="no" border="0" marginwidth="0" style={{ border: 'none' }} frameborder="0"></iframe>

                </div>
            </> :
                <h1 className="displaySizeErr">You must open this website on a computer to perform any tests.</h1>
            }
        </div>
    )
}

export default Home
