import React, { useState, useEffect, useRef } from 'react'
import { browserName } from 'react-device-detect';
import './MicTest.css'

function MicTest() {

  // const [micMessage, setMicMessage] = useState('please allow the use of your microphone')
  const visualizerRef = useRef();
  const maskRef = useRef();
  let path;

  useEffect(() => {
    let soundNotAllowed = function (error) {
      alert("please reload and enable your microphone.")
      console.log(error);
    }

    if(browserName === "Chrome") {
      navigator.getUserMedia({ audio: true }, soundAllowed, soundNotAllowed);
    }
    else {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        soundAllowed(stream)
      }).catch((error) => {
        soundNotAllowed(error)
      });
    }
  }, [])



  let soundAllowed = function (stream) {
    //Audio stops listening in FF without // window.persistAudioStream = stream;
    //https://bugzilla.mozilla.org/show_bug.cgi?id=965483
    //https://support.mozilla.org/en-US/questions/984179
    window.persistAudioStream = stream;
    // setMicMessage("Thanks")
    let audioContent = new AudioContext();
    let audioStream = audioContent.createMediaStreamSource(stream);
    let analyser = audioContent.createAnalyser();
    audioStream.connect(analyser);
    analyser.fftSize = 1024;
    let frequencyCheckNum = 270

    let frequencyArray = new Uint8Array(analyser.frequencyBinCount);
    if (visualizerRef.current) {
      visualizerRef.current.setAttribute('viewBox', `0 0 ${frequencyCheckNum} ${frequencyCheckNum}`);
    }

    //Through the frequencyArray has a length longer than 255, there seems to be no
    //significant data after this point. Not worth visualizing.
    for (let i = 0; i < frequencyCheckNum; i++) {
      path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('stroke-dasharray', '4,1');
      path.setAttribute('stroke-dasharray', '4,1');
      if (maskRef.current) {
        maskRef.current.appendChild(path);
      }
    }

    let doDraw = function () {
      requestAnimationFrame(doDraw);
      analyser.getByteFrequencyData(frequencyArray);
      let adjustedLength;
      let paths = document.getElementsByTagName('path');
      for (let i = 0; i < frequencyCheckNum; i++) {
        if (paths[i]) {
          adjustedLength = Math.floor(frequencyArray[i]) - (Math.floor(frequencyArray[i]) % 5);
          paths[i].setAttribute('d', 'M ' + (i) + `,${frequencyCheckNum} l 0,-` + adjustedLength);
        }
      }
    }
    doDraw();
  }

  return (
    <div className="MicTestContainer">
      <div>
        <svg preserveAspectRatio="none" ref={visualizerRef} id="visualizer" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <mask ref={maskRef} id="mask">
              <g id="maskGroup"></g>
            </mask>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: "#ff0a0a", stopOpacity: 1 }} />
              <stop offset="20%" style={{ stopColor: "#f1ff0a", stopOpacity: 1 }} />
              <stop offset="90%" style={{ stopColor: "#d923b9", stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: "#050d61", stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
        </svg>
      </div>
    </div >
  )
}

export default MicTest
