import React, { useEffect, useState } from 'react'

// let stop = false

function KeyComponent({ keyProps, playSound }) {

    const [keyPressedBool, setKeyPressedBool] = useState(false)
    const [keyPressed, setKeyPressed] = useState(null)

    useEffect(() => {
        // if (stop) { 
        //     return 
        // } else {
            window.addEventListener("keydown", (downHandler));
        //     stop = true
        // }
    }, [])

    function downHandler(e) {
        e.preventDefault()
        setKeyPressed(e)
    }

    useEffect(() => {
        if (keyPressed !== null) {
            if (playSound) {
                playSound()
            }
            if (keyPressed.keyCode === keyProps.val) {
                if (keyProps.location === '') {
                    setKeyPressedBool(true)
                }
                else {
                    if (keyPressed.location === keyProps.location) {
                        setKeyPressedBool(true)
                    }
                }
            }
        }
    }, [keyPressed])



    return (
        <div style={{ width: keyProps.width }} className={keyPressedBool === false ? "keyboardKey keyNotClicked" : "keyboardKey keyClicked"}>
            <p>{keyProps.key}</p>
        </div>
    )
}

export default KeyComponent
