import React, { useEffect, useState } from 'react'
import useSound from 'use-sound';
import KeyComponent from '../KeyComponent/KeyComponent'
import './KeyboardTest.css'

import keyPress from '../../mp3/keypress.mp3'
let playing = false

const keys = {
    section1: [
        { key: 'Esc', val: 27, width: '7.1%', location: "" },
        { key: 'f1', val: 112, width: '7.1%', location: "" },
        { key: 'f2', val: 113, width: '7.1%', location: "" },
        { key: 'f3', val: 114, width: '7.1%', location: "" },
        { key: 'f4', val: 115, width: '7.1%', location: "" },
        { key: 'f5', val: 116, width: '7.1%', location: "" },
        { key: 'f6', val: 117, width: '7.1%', location: "" },
        { key: 'f7', val: 118, width: '7.1%', location: "" },
        { key: 'f8', val: 119, width: '7.1%', location: "" },
        { key: 'f9', val: 120, width: '7.1%', location: "" },
        { key: 'f10', val: 121, width: '7.1%', location: "" },
        { key: 'f11', val: 122, width: '7.1%', location: "" },
        { key: 'f12', val: 123, width: '7.1%', location: "" }
    ],
    section2: [
        [
            { key: '~', val: 192, width: '6.6%', location: "" },
            { key: '1', val: 49, width: '6.6%', location: "" },
            { key: '2', val: 50, width: '6.6%', location: "" },
            { key: '3', val: 51, width: '6.6%', location: "" },
            { key: '4', val: 52, width: '6.6%', location: "" },
            { key: '5', val: 53, width: '6.6%', location: "" },
            { key: '6', val: 54, width: '6.6%', location: "" },
            { key: '7', val: 55, width: '6.6%', location: "" },
            { key: '8', val: 56, width: '6.6%', location: "" },
            { key: '9', val: 57, width: '6.6%', location: "" },
            { key: '0', val: 48, width: '6.6%', location: "" },
            { key: '-', val: 189, width: '6.6%', location: "" },
            { key: '+', val: 187, width: '6.6%', location: "" },
            { key: 'Back', val: 8, width: '6.6%', location: "" }
        ],
        [
            { key: 'Tab', val: 9, width: '6.6%', location: "" },
            { key: 'q', val: 81, width: '6.6%', location: "" },
            { key: 'w', val: 87, width: '6.6%', location: "" },
            { key: 'e', val: 69, width: '6.6%', location: "" },
            { key: 'r', val: 82, width: '6.6%', location: "" },
            { key: 't', val: 84, width: '6.6%', location: "" },
            { key: 'y', val: 89, width: '6.6%', location: "" },
            { key: 'u', val: 85, width: '6.6%', location: "" },
            { key: 'i', val: 73, width: '6.6%', location: "" },
            { key: 'o', val: 79, width: '6.6%', location: "" },
            { key: 'p', val: 80, width: '6.6%', location: "" },
            { key: '{', val: 219, width: '6.6%', location: "" },
            { key: '}', val: 221, width: '6.6%', location: "" },
            { key: '\\', val: 220, width: '6.6%', location: "" }
        ],
        [
            { key: 'Caps', val: 20, width: '10%', location: "" },
            { key: 'a', val: 65, width: '6.6%', location: "" },
            { key: 's', val: 83, width: '6.6%', location: "" },
            { key: 'd', val: 68, width: '6.6%', location: "" },
            { key: 'f', val: 70, width: '6.6%', location: "" },
            { key: 'g', val: 71, width: '6.6%', location: "" },
            { key: 'h', val: 72, width: '6.6%', location: "" },
            { key: 'j', val: 74, width: '6.6%', location: "" },
            { key: 'k', val: 75, width: '6.6%', location: "" },
            { key: 'l', val: 76, width: '6.6%', location: "" },
            { key: ';', val: 186, width: '6.6%', location: "" },
            { key: '\'', val: 222, width: '6.6%', location: "" },
            { key: 'Enter', val: 13, width: '10%', location: 0 }
        ],
        [
            { key: 'Shift', val: 16, width: '13.6%', location: 1 },
            { key: 'z', val: 90, width: '6.6%', location: "" },
            { key: 'x', val: 88, width: '6.6%', location: "" },
            { key: 'c', val: 67, width: '6.6%', location: "" },
            { key: 'v', val: 86, width: '6.6%', location: "" },
            { key: 'b', val: 66, width: '6.6%', location: "" },
            { key: 'n', val: 78, width: '6.6%', location: "" },
            { key: 'm', val: 77, width: '6.6%', location: "" },
            { key: ',', val: 188, width: '6.6%', location: "" },
            { key: '.', val: 190, width: '6.6%', location: "" },
            { key: '/', val: 191, width: '6.6%', location: "" },
            { key: 'Shift', val: 16, width: '13.6%', location: 2 }
        ],
        [
            { key: 'Ctrl', val: 17, width: '6.6%', location: 1 },
            { key: 'Win', val: 91, width: '6.6%', location: "" },
            { key: 'Alt', val: 18, width: '6.6%', location: 1 },
            { key: 'Com', val: 91, width: '6.6%', location: "" },
            { key: 'Spacebar', val: 32, width: '36%', location: "" },
            { key: 'Com', val: 93, width: '6.6%', location: "" },
            { key: 'Alt', val: 18, width: '6.6%', location: 2 },
            { key: 'Win', val: 92, width: '6.6%', location: "" },
            { key: 'sel', val: 93, width: '6.6%', location: "" },
            { key: 'Ctrl', val: 17, width: '6.6%', location: 2 }
        ]
    ],
    section3: [
        [
            { key: 'Prnt Scrn', val: 44, width: '31.5%', location: "" },
            { key: 'Scroll Lock', val: 145, width: '31.5%', location: "" },
            { key: 'Pause', val: 19, width: '31.5%', location: "" },
        ],
        [
            { key: 'Insert', val: 45, width: '31.5%', location: "" },
            { key: 'Home', val: 36, width: '31.5%', location: "" },
            { key: 'PgUp', val: 33, width: '31.5%', location: "" },
        ],
        [
            { key: 'Delete', val: 46, width: '31.5%', location: "" },
            { key: 'End', val: 35, width: '31.5%', location: "" },
            { key: 'PgDwn', val: 34, width: '31.5%', location: "" },
        ]
    ],
    section4: [
        [
            { key: 'up', val: 38, width: '31.5%', location: "" },
        ],
        [
            { key: 'left', val: 37, width: '31.5%', location: "" },
            { key: 'down', val: 40, width: '31.5%', location: "" },
            { key: 'right', val: 39, width: '31.5%', location: "" },
        ]
    ],
    section5: [
        [
            { key: 'num lock', val: 144, width: '31.1%', location: "" },
            { key: '/', val: 111, width: '31.1%', location: "" },
            { key: '*', val: 106, width: '31.1%', location: "" },
        ],
        [
            { key: '7', val: 103, width: '31.1%', location: "" },
            { key: '8', val: 104, width: '31.1%', location: "" },
            { key: '9', val: 105, width: '31.1%', location: "" },
        ],
        [
            { key: '4', val: 100, width: '31.1%', location: "" },
            { key: '5', val: 101, width: '31.1%', location: "" },
            { key: '6', val: 102, width: '31.1%', location: "" },
        ],
        [
            { key: '1', val: 97, width: '31.1%', location: "" },
            { key: '2', val: 98, width: '31.1%', location: "" },
            { key: '3', val: 99, width: '31.1%', location: "" },
        ],
        [
            { key: '0', val: 96, width: "65%", location: "" },
            { key: '.', val: 110, width: '31.1%', location: "" },
        ]
    ],
    section6: [
        [
            { key: '-', val: 109, width: '100%', location: "" },
        ],
        [
            { key: '+', val: 107, width: '100%', location: "" },
        ],
        [
            { key: 'Enter', val: 13, width: '100%', location: 3 },
        ]
    ]
}


function KeyboardTest() {
    const [play] = useSound(keyPress);

    const playSound = async () => {
        if (playing === false) {
            play()
            playing = true
            setTimeout(() => {
                playing = false
            }, 150);
        }
    }

    return (
        <div className="keyboardContainer">
            <div className="keyboardCol1 keyboardCol">
                <div className="keyboardRow ColTopRow">
                    {keys.section1.map((e, i) => {
                        return (
                            <KeyComponent
                                key={"Sec1-" + i}
                                keyProps={e}
                                playSound={playSound}
                            />
                        )
                    })}
                </div>
                {keys.section2.map((arr, idx) => {
                    return (
                        <div className="keyboardRow" key={"Sec2-" + idx}>
                            {arr.map((e, i) => {
                                return (
                                    <KeyComponent
                                        key={"Sec2-" + idx + "-" + i}
                                        keyProps={e}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="keyboardCol2 keyboardCol">
                {keys.section3.map((arr, idx) => {
                    return (
                        <div className={idx === 0 ? "keyboardRow ColTopRow" : "keyboardRow"} key={"Sec3-" + idx}>
                            {arr.map((e, i) => {
                                return (
                                    <KeyComponent
                                        key={"Sec3-" + idx + "-" + i}
                                        keyProps={e}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
                {keys.section4.map((arr, idx) => {
                    return (
                        <div className={idx === 0 ? "keyboardRow ArrowKeySec" : "keyboardRow"} key={"Sec4-" + idx}>
                            {arr.map((e, i) => {
                                return (
                                    <KeyComponent
                                        key={"Sec4-" + idx + "-" + i}
                                        keyProps={e}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="keyboardCol3">
                <div className="keyboardSubCol1 keyboardCol">
                    {keys.section5.map((arr, idx) => {
                        return (
                            <div className={idx === 0 ? "keyboardRow numPadMargin" : "keyboardRow"} key={"Sec5-" + idx}>
                                {arr.map((e, i) => {
                                    return (
                                        <KeyComponent
                                            key={"Sec5-" + idx + "-" + i}
                                            keyProps={e}
                                        />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="keyboardSubCol2 keyboardCol">
                    {keys.section6.map((arr, idx) => {
                        return (
                            <div style={idx !== 0 ? { height: '30%' } : {}} className={idx === 0 ? "keyboardRow numPadMargin" : "keyboardRow"} key={"Sec6-" + idx}>
                                {arr.map((e, i) => {
                                    return (
                                        <KeyComponent
                                            key={"Sec6-" + idx + "-" + i}
                                            keyProps={e}
                                        />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default KeyboardTest
