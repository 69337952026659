import React from 'react'
import './WebCamTest.css'

import Webcam from "react-webcam";

function WebCamTest() {
  return (
    <div className="WebCamTestContainer">
      <Webcam />
    </div>
  )
}

export default WebCamTest
