import React from 'react'
import './Header.css'

function Header() {
  return (
    <div className="headerContainer">
      <a className="companyName" href="https://www.oradatech.com/" target="_Blank" rel="noreferrer">Orada Tech</a>
      <div>
        <a className="contactLinks" href="https://www.oradatech.com/about" target="_Blank" rel="noreferrer">About Us</a>
        <a className="contactLinks" href="mailto:support@oradatech.com">support@oradatech.com</a>
        <a className="contactLinks" href="tel:+17322263351">732-226-3351</a>
      </div>
    </div>
  )
}

export default Header
